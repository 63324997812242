// vrScenarios.js

import CBLevel1 from './CB-Level1.vue'; // Correct path to CB-Level1.vue
import CBLevel2 from './CB-Level2.vue'; // Correct path to CB-Level1.vue
import CBLevel3 from './CB-Level3.vue'; // Correct path to CB-Level1.vue
import CBLevel4 from './CB-Level4.vue'; // Correct path to CB-Level1.vue


export const vrScenarios = [
  {
    id: 'cb-level-1',  // Ensure this ID matches the one used in the selection
    name: 'Conveyor Belt - Excercise 1',
    component: CBLevel1, // Ensure this points to the correct component
  },
  {
    id: 'cb-level-2',  // Ensure this ID matches the one used in the selection
    name: 'Conveyor Belt - Excercise 2',
    component: CBLevel2, // Ensure this points to the correct component
  },
  {
    id: 'cb-level-3',  // Ensure this ID matches the one used in the selection
    name: 'Conveyor Belt - Excercise 3',
    component: CBLevel3, // Ensure this points to the correct component
  },
  {
    id: 'cb-level-4',  // Ensure this ID matches the one used in the selection
    name: 'Conveyor Belt - Excercise 4',
    component: CBLevel4, // Ensure this points to the correct component
  },
  // Add more scenarios as needed
];

