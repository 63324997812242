<template>
  <div class="digital-twin-container">
    <div class="header">
      <h3>Choose your VR scenario</h3>
      <v-btn class="load-vr-button" @click="toggleScenarioList">
        Load VR Scene
      </v-btn>
    </div>

    <!-- Show the scenario list when user clicks the Load VR button -->
    <div v-if="showScenarioList" class="scenario-list">
      <v-btn
        class="nav-button"
        :disabled="currentPage === 1"
        @click="previousPage"
      >
        Previous
      </v-btn>

      <div class="scenarios">
        <!-- Display up to 3 scenarios per page -->
        <div
          v-for="scenario in paginatedScenarios"
          :key="scenario.id"
          class="scenario-item"
          @click="loadSelectedScene(scenario)"
          :class="{ selected: selectedScenario && selectedScenario.id === scenario.id }"
        >
          <h3>{{ scenario.name }}</h3>
          <img :src="scenario.thumbnail" alt="Scenario Thumbnail" />
        </div>
      </div>

      <v-btn
        class="nav-button"
        :disabled="currentPage === totalPages"
        @click="nextPage"
      >
        Next
      </v-btn>
    </div>

    <!-- Currently Loaded Scenario -->
    <div v-if="loadedScenario" class="loaded-scenario-info">
      Currently Loaded: {{ loadedScenario.name }}
    </div>

    <!-- VR Scene Box -->
    <div v-if="loadedScenario" class="vr-scene-box">
      <component :is="currentVRScene" />
    </div>
  </div>
</template>

<script>
import { vrScenarios } from "./vrScenarios.js"; // Adjust the path as necessary
import { useStore } from "vuex";
import { ref, computed, onMounted } from "vue";

export default {
  name: "DigitalTwin",
  setup() {
    const store = useStore();

    // Reactive variables
    const selectedScenario = ref(null);
    const loadedScenario = ref(null);
    const currentVRScene = ref(null);
    const currentPage = ref(1);
    const itemsPerPage = 3;
    const showScenarioList = ref(false);

    // VR scenarios data
    const vrScenariosList = ref(vrScenarios);

    // Computed properties for pagination
    const totalPages = computed(() => Math.ceil(vrScenariosList.value.length / itemsPerPage));
    const paginatedScenarios = computed(() => {
      const start = (currentPage.value - 1) * itemsPerPage;
      return vrScenariosList.value.slice(start, start + itemsPerPage);
    });

    // Toggle scenario list visibility
    const toggleScenarioList = () => {
      showScenarioList.value = !showScenarioList.value;
    };

    // Load selected VR scene
    const loadSelectedScene = (scenario) => {
      if (scenario) {
        selectedScenario.value = scenario;
        loadedScenario.value = scenario;
        currentVRScene.value = scenario.component;
        showScenarioList.value = false; // Hide the scenario list after loading the scene
        store.dispatch("setDigitalTwinToRun", { vrScenarioId: scenario.id });
      }
    };

    // Pagination methods
    const previousPage = () => {
      if (currentPage.value > 1) {
        currentPage.value--;
      }
    };

    const nextPage = () => {
      if (currentPage.value < totalPages.value) {
        currentPage.value++;
      }
    };

    // Load the previously selected VR scenario if available
    onMounted(() => {
      const digitalTwinData = store.getters.getDigitalTwin;
      if (digitalTwinData && digitalTwinData.vrScenarioId) {
        const selected = vrScenariosList.value.find((s) => s.id === digitalTwinData.vrScenarioId);
        if (selected) {
          loadSelectedScene(selected);
        }
      }
    });

    return {
      vrScenariosList,
      selectedScenario,
      loadedScenario,
      currentVRScene,
      currentPage,
      itemsPerPage,
      showScenarioList,
      totalPages,
      paginatedScenarios,
      toggleScenarioList,
      loadSelectedScene,
      previousPage,
      nextPage,
    };
  },
};
</script>

<style scoped>
/* Styles remain unchanged */
.digital-twin-container {
  padding: 20px;
}

/* Title styling */
h3 {
  color: #03abff;
  font-size: 1.5rem; /* This is equivalent to h4 size */
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.load-vr-button {
  background: linear-gradient(to bottom, #ffc300, #ffd60a);
  color: black;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
}

.load-vr-button:hover {
  filter: brightness(0.9);
}

.scenario-list {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.nav-button {
  background: linear-gradient(to bottom, #03abff, #005792);
  color: white;
  border: none;
  padding: 10px 20px;
  margin: 0 10px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
}

.nav-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.scenarios {
  display: flex;
  gap: 20px;
}

.scenario-item {
  border: 2px solid #03abff;
  border-radius: 10px;
  padding: 10px;
  cursor: pointer;
  text-align: center;
  width: 150px;
}

.scenario-item.selected {
  border-color: #ffc300;
}

.scenario-item img {
  width: 100%;
  height: auto;
  border-radius: 5px;
}

.loaded-scenario-info {
  margin-top: 20px;
  padding: 10px;
  background: linear-gradient(to bottom, #ffc300, #ffd60a);
  color: #000814;
  font-weight: bold;
  border-radius: 5px;
}

.vr-scene-box {
  margin-top: 20px;
  border: 2px solid #03abff;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  width: 100%;
  max-width: 100%;
}

.canvas-container {
  width: 100%;
  height: 100%;
}
</style>
