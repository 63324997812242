export function getCBLevel1VRTags() {
    return [
      { id: "start", name: "Start Button", type: "Input", dataType: "Bool", value: false, linkId: undefined },
      { id: "stop", name: "Stop Button", type: "Input", dataType: "Bool", value: false, linkId: undefined },
      { id: "motor1", name: "Motor 1", type: "Output", dataType: "Bool", value: false, linkId: undefined },
    ];
  }

  export function getCBLevel2VRTags() {
    return [
      { id: "start", name: "Start Button", type: "Input", dataType: "Bool", value: false, linkId: undefined },
      { id: "stop", name: "Stop Button", type: "Input", dataType: "Bool", value: false, linkId: undefined },
      { id: "motor1", name: "Motor 1", type: "Output", dataType: "Bool", value: false, linkId: undefined },
      { id: "processLights", name: "Process Active Lights", type: "Output", dataType: "Bool", value: false, linkId: undefined },
    ];
  }
  
export function getCBLevel3VRTags() {
  return [
    { id: "start", name: "Start Button", type: "Input", dataType: "Bool", value: false, linkId: undefined },
    { id: "stop", name: "Stop Button", type: "Input", dataType: "Bool", value: false, linkId: undefined },
    { id: "motor1", name: "Motor 1", type: "Output", dataType: "Bool", value: false, linkId: undefined },
    { id: "processLights", name: "Process Active Lights", type: "Output", dataType: "Bool", value: false, linkId: undefined },
    { id: "sensor1", name: "Sensor 1", type: "Input", dataType: "Bool", value: false, linkId: undefined },
  ];
}

export function getCBLevel4VRTags() {
  return [
    { id: "start", name: "Start Button", type: "Input", dataType: "Bool", value: false, linkId: undefined },
    { id: "stop", name: "Stop Button", type: "Input", dataType: "Bool", value: false, linkId: undefined },
    { id: "motor1", name: "Motor 1", type: "Output", dataType: "Bool", value: false, linkId: undefined },
    { id: "processLights", name: "Process Active Lights", type: "Output", dataType: "Bool", value: false, linkId: undefined },
    { id: "sensor1", name: "Sensor 1", type: "Input", dataType: "Bool", value: false, linkId: undefined },
    { id: "warningLamp1", name: "Warning_Lamp_1", type: "Output", dataType: "Bool", value: false, linkId: undefined },
  ];
}