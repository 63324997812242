<template>
    <div class="data-sources-container">
      <!-- Row for Ladder Logic Tags and VR Tags Selection -->
      <div class="row">
        <!-- Ladder Logic Tags Section -->
        <div class="section">
          <h2>Choose Your Data Sources</h2>
          <div class="input-group">
            <label class="label-text">Select a Ladder Logic Diagram</label>
            <select class="input-field" v-model="selectedLadderLogicDiagram">
              <option v-for="diagram in ladderLogicDiagrams" :key="diagram.id" :value="diagram.id">
                {{ diagram.name }}
              </option>
            </select>
          </div>
        </div>
  
        <!-- VR Tags Section -->
        <div class="section">
          <h2>Virtual Reality Scenarios</h2>
          <div class="input-group">
            <label class="label-text">Select a VR Scenario</label>
            <select class="input-field" v-model="selectedVRScenario">
              <option v-for="scenario in vrScenarioOptions" :key="scenario.id" :value="scenario.id">
                {{ scenario.name }}
              </option>
            </select>
          </div>
        </div>
      </div>
  
      <!-- Row for Ladder Logic Variables Table and VR Tags Table -->
      <div class="row">
        <!-- Ladder Logic Variables Table -->
        <div v-if="variablesTable.length > 0" class="section table-section">
          <h2>Ladder Logic Tags</h2>
          <table class="virtual-tags-table">
            <thead>
              <tr>
                <th>ID</th>
                <th>Class</th>
                <th>Type</th>
                <th>Value</th>
                <th>Link ID</th>
                <th>Link</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(variable, index) in variablesTable" :key="variable.id">
                <td>{{ variable.id }}</td>
                <td>{{ variable.class }}</td>
                <td>{{ variable.type }}</td>
                <td>{{ variable.value ? '1' : '0' }}</td>
                <td>{{ variable.linkId }}</td>
                <td>
                  <input
                    type="checkbox"
                    :checked="selectedLadderLogicTag === index"
                    @change="selectLadderLogicTag(index)"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
  
        <!-- VR Tags Table -->
        <div v-if="vrTagsTable.length > 0" class="section table-section">
          <h2>Virtual Reality Tags</h2>
          <table class="virtual-tags-table">
            <thead>
              <tr>
                <th>Tag Name</th>
                <th>Type</th>
                <th>Data Type</th>
                <th>Value</th>
                <th>Link ID</th>
                <th>Link</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(tag, index) in vrTagsTable" :key="tag.id">
                <td>{{ tag.name }}</td>
                <td>{{ tag.type }}</td>
                <td>{{ tag.dataType }}</td>
                <td>{{ tag.value ? '1' : '0' }}</td>
                <td>{{ tag.linkId !== undefined ? tag.linkId : '' }}</td>
                <td>
                  <input
                    type="checkbox"
                    :checked="selectedVrTag === index"
                    @change="selectVrTag(index)"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
  
        <!-- Start Connection Button -->
        <div class="connection-button-wrapper">
        <button class="connection-button" @click="startConnection">Start Connection</button>
        </div>

    </div>
  </template>
  
  <script>
import axios from "axios";
import { vrScenarios } from "./vrScenarios.js"; // Adjust the path as necessary
import { useStore } from "vuex";
import { ref, computed, watch, onMounted, onUnmounted } from "vue";
import { getCBLevel1VRTags, getCBLevel2VRTags, getCBLevel3VRTags, getCBLevel4VRTags  } from './vrTagFunctions.js';  // Import the VR tag function directly


export default {
  name: "IIoTDataSources",
  setup() {
    const store = useStore();

    // Load state from Vuex if available
    //const iiotState = store.getters.getIIoTDataSources;

    // Local state variables with initial values from Vuex
    const ladderLogicDiagrams = ref([]);
    const vrScenarioOptions = ref([]);
    const selectedLadderLogicDiagram = computed({
      get: () => store.state.iiotDataSources.selectedDiagramId,
      set: (value) => store.dispatch("setIIoTDataSources", { ...store.state.iiotDataSources, selectedDiagramId: value }),
    });
    const selectedVRScenario = computed({
      get: () => store.state.iiotDataSources.selectedVRScenarioId,
      set: (value) => store.dispatch("setIIoTDataSources", { ...store.state.iiotDataSources, selectedVRScenarioId: value }),
    });
    const variablesTable = computed(() => store.state.iiotDataSources.variablesTable);
    const vrTagsTable = computed(() => store.state.iiotDataSources.vrTagsTable);
    const selectedLadderLogicTag = ref(null);
    const selectedVrTag = ref(null);

    // Watchers to update Vuex whenever there's a change
    watch(
      [selectedLadderLogicDiagram, selectedVRScenario],
      () => {
        store.dispatch("setIIoTDataSources", {
          ...store.state.iiotDataSources,
          selectedDiagramId: selectedLadderLogicDiagram.value,
          selectedVRScenarioId: selectedVRScenario.value,
        });
      },
      { deep: true }
    );

    // Add this function inside the `setup` function in your `IIoTDataSources.vue` script
        const handleVrTagUpdate = (tagName, value) => {
        const tag = vrTagsTable.value.find((t) => t.name === tagName);
        if (tag) {
            tag.value = value ? 1 : 0; // Update value based on the event (1 for true, 0 for false)
        }
        };

    // Fetch Ladder Logic Diagrams from backend
    const fetchLadderLogicDiagrams = () => {
      const token = localStorage.getItem("jwt");
      axios
        .get("/yaga-auth/ladder-logic", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          ladderLogicDiagrams.value = response.data;
        })
        .catch((error) => {
          console.error("Error fetching ladder logic diagrams:", error);
        });
    };

    // Load VR Scenarios
    const loadVRScenarios = () => {
      vrScenarioOptions.value = vrScenarios.map((scenario) => ({
        id: scenario.id,
        name: scenario.name,
        component: scenario.component,
      }));
    };

 // Load Ladder Logic Diagram Variables
const loadLadderLogicDiagram = async (diagramId) => {
  try {
    const token = localStorage.getItem("jwt");
    const response = await axios.get(`/yaga-auth/ladder-logic/${diagramId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.status === 200) {
      const { variables } = response.data;
      const parsedVariables = variables ? JSON.parse(variables) : [];

      // Dispatch the updated variables to Vuex store
      store.dispatch("setIIoTDataSources", {
        ...store.state.iiotDataSources,
        variablesTable: parsedVariables,
      });
    }
  } catch (error) {
    console.error("Error fetching diagram details:", error);
  }
};

    // Modify the loadVRTags function to load tags based on the selected scenario ID
    const loadVRTags = (scenarioId) => {
      // Clear the current tags table
      store.dispatch("setIIoTDataSources", { ...store.state.iiotDataSources, vrTagsTable: [] });

      let vrTags = [];

      // Load VR tags based on the scenario ID
      if (scenarioId === 'cb-level-1') {
        vrTags = getCBLevel1VRTags();  // Call the function directly from vrTagFunctions.js
      } else if (scenarioId === 'cb-level-2') {
    vrTags = getCBLevel2VRTags();
  } else if (scenarioId === 'cb-level-3') {
    vrTags = getCBLevel3VRTags();
  } else if (scenarioId === 'cb-level-4') {
    vrTags = getCBLevel4VRTags();
  }

      if (vrTags.length > 0) {
        // Dispatch the updated VR tags to Vuex store
        store.dispatch("setIIoTDataSources", {
          ...store.state.iiotDataSources,
          vrTagsTable: vrTags,
        });
      } else {
        console.warn(`VR Tags could not be loaded for scenario ID ${scenarioId}`);
      }
    };


// Link Tags
const linkTags = () => {
  const ladderLogicTag = variablesTable.value[selectedLadderLogicTag.value];
  const vrTag = vrTagsTable.value[selectedVrTag.value];

  // Assign the link ID and direction from the ladder logic tag to the VR tag
  vrTag.linkId = ladderLogicTag.id; // Use the 'id' as the linkId
  ladderLogicTag.linkId = ladderLogicTag.id; // Ensure ladder logic variable has the linkId

  // Assign direction based on VR Tag type
  vrTag.direction = vrTag.type === 'Input' ? 'input' : 'output';
  
  console.log('IIOT- Linking VR Tag:', vrTag);
  console.log('IIOT- With Ladder Logic Variable:', ladderLogicTag);

  // Reset the selections
  selectedLadderLogicTag.value = null;
  selectedVrTag.value = null;
};



    // Handle Ladder Logic Tag Selection
    const selectLadderLogicTag = (index) => {
      if (selectedLadderLogicTag.value === index) {
        selectedLadderLogicTag.value = null; // Deselect if clicked again
      } else {
        selectedLadderLogicTag.value = index;
        // Link if both Ladder Logic and VR tags are selected
        if (selectedVrTag.value !== null) {
          linkTags();
        }
      }
    };

    // Handle VR Tag Selection
    const selectVrTag = (index) => {
      if (selectedVrTag.value === index) {
        selectedVrTag.value = null; // Deselect if clicked again
      } else {
        selectedVrTag.value = index;
        // Link if both Ladder Logic and VR tags are selected
        if (selectedLadderLogicTag.value !== null) {
          linkTags();
        }
      }
    };

// Start Connection between Ladder Logic and VR Scenario
    const startConnection = () => {
    if (!selectedLadderLogicDiagram.value || !selectedVRScenario.value) {
        alert("Please select both a Ladder Logic Diagram and a VR Scenario.");
        return;
    }

    store.dispatch("setLadderLogicToRun", {
        diagramId: selectedLadderLogicDiagram.value,
        runMode: true,
    });
    store.dispatch("setDigitalTwinToRun", {
        vrScenarioId: selectedVRScenario.value,
    });

    // Dispatch an action to set selectedMenu to 'avsimulation'
    store.dispatch("setSelectedMenu", 'avsimulation');
    };


// Watch for selected Ladder Logic Diagram
watch(selectedLadderLogicDiagram, (newVal) => {
  if (newVal) {
    loadLadderLogicDiagram(newVal);
  } else {
    // Clear the variables table if no diagram is selected
    store.dispatch("setIIoTDataSources", {
      ...store.state.iiotDataSources,
      variablesTable: [],
    });
  }
});

// Watch for selected VR Scenario
watch(selectedVRScenario, (newVal) => {
  if (newVal) {
    loadVRTags(newVal);
  } else {
    // Clear the VR tags table if no scenario is selected
    store.dispatch("setIIoTDataSources", {
      ...store.state.iiotDataSources,
      vrTagsTable: [],
    });
  }
});


watch(
  () => [...vrTagsTable.value], // Spread to create a shallow copy and trigger updates properly
  (newTags, oldTags) => {
    newTags.forEach((newTag) => {
      const oldTag = oldTags.find((t) => t.id === newTag.id);

      // Only process the tag if its value has changed
      if (oldTag && newTag.value !== oldTag.value) {
        console.log(`IIOT- VR Tag Changed: ${newTag.name}, Value: ${newTag.value}, linkId: ${newTag.linkId}`);
        
        if (newTag.linkId) {
          // Find the linked ladder logic variable based on linkId
          const linkedVariable = variablesTable.value.find((variable) => variable.id === newTag.linkId);
          
          if (linkedVariable) {
            if (newTag.direction === 'input') {
              console.log(`IIOT- Input Linked Variable Found: ${linkedVariable.id}, Updating with value: ${newTag.value}`);
              // Dispatch to Vuex to update the state in LLBuilder
              store.dispatch("toggleLadderLogicContact", {
                elementId: linkedVariable.id,
                newState: newTag.value,
              });
            } else if (newTag.direction === 'output') {
              console.log(`IIOT- Output Linked Variable Found: ${linkedVariable.id}, Listening to Ladder Logic changes.`);
              // Listen to Ladder Logic changes for output tags (handled by a separate watcher below)
            }
          } else {
            console.log("IIOT- No linked variable found for linkId:", newTag.linkId);
          }
        } else {
          console.log("IIOT- VR Tag does not have a valid linkId.");
        }
      }
    });
  },
  { deep: true }
);

watch(
  () => [...variablesTable.value], // Watch for changes in Ladder Logic Variables
  (newVariables, oldVariables) => {
    newVariables.forEach((newVariable) => {
      const oldVariable = oldVariables.find((v) => v.id === newVariable.id);

      // Only process if the value has changed
      if (oldVariable && newVariable.value !== oldVariable.value) {
        // Find the linked VR tag
        const linkedVrTag = vrTagsTable.value.find((tag) => tag.linkId === newVariable.id && tag.direction === 'output');

        if (linkedVrTag) {
          console.log(`IIOT- Ladder Logic Output Changed: ${newVariable.id}, Updating VR Tag: ${linkedVrTag.name} with value: ${newVariable.value}`);
          // Dispatch to update VR Tag value
          store.dispatch("updateVrTagValue", { tagName: linkedVrTag.name, value: newVariable.value });
        }
      }
    });
  },
  { deep: true }
);







    // Load diagrams and scenarios on mount
    onMounted(() => {
      fetchLadderLogicDiagrams();
      loadVRScenarios();
      window.addEventListener("start-button-pressed", () => {
    handleVrTagUpdate("Start Button", true);
    setTimeout(() => handleVrTagUpdate("Start Button", false), 100);
  });
  window.addEventListener("stop-button-pressed", () => {
    handleVrTagUpdate("Stop Button", true);
    setTimeout(() => handleVrTagUpdate("Stop Button", false), 100);
  });
    });

    // Clean up event listeners on unmount
onUnmounted(() => {
  window.removeEventListener("start-button-pressed", () => handleVrTagUpdate("Start Button", false));
  window.removeEventListener("stop-button-pressed", () => handleVrTagUpdate("Stop Button", false));
});

    return {
      ladderLogicDiagrams,
      vrScenarioOptions,
      selectedLadderLogicDiagram,
      selectedVRScenario,
      variablesTable,
      vrTagsTable,
      selectedLadderLogicTag,
      selectedVrTag,
      selectLadderLogicTag,
      selectVrTag,
      startConnection,
    };
  },
};
</script>
  


  <style scoped>
 .data-sources-container {
  padding: 20px;
}

.row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  flex-wrap: wrap; /* Add this to allow wrapping of sections */
}

.section {
  display: flex;
  flex-direction: column;
  width: 48%;
}

.table-section {
  margin-top: 20px;
}

h2 {
  color: #03abff;
  margin-bottom: 10px;
}

.label-text {
  color: #ffc300;
  font-weight: bold;
}

.input-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.input-field {
  background-color: #1b263b;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 10px;
  color: lightgray;
  font-weight: normal;
}

select.input-field {
  background-color: #1b263b;
  color: white;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 10px;
}

select.input-field option {
  background-color: #1b263b;
  color: white;
}

select.input-field option:hover {
  background-color: #ffc300;
}

/* Styles for the virtual tags table */
.virtual-tags-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.virtual-tags-table th,
.virtual-tags-table td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: left;
}

.virtual-tags-table th {
  background-color: #1b263b;
  color: #ffc300;
}

.virtual-tags-table td {
  background-color: #1b263b;
  color: lightgray;
}

.connection-button-wrapper {
  display: flex;
  justify-content: center; /* Centers the button horizontally */
  margin-top: 20px; /* Add some space on top */
}

.connection-button {
  background: linear-gradient(to bottom, #ffc300, #ffd60a);
  color: #000814;
  font-weight: bold;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.connection-button:hover {
  filter: brightness(0.9);
}

/* Media query for smaller screens */
@media screen and (max-width: 950px) {
  .row {
    flex-direction: column; /* Stack the sections vertically */
  }

  .section {
    width: 100%; /* Full width for each section */
    margin-bottom: 20px; /* Add some space between the sections */
  }
}


  </style>
  