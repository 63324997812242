<template>
    <v-container>
      <!-- User Greeting and Profile Type -->
      <v-row class="d-flex justify-space-between align-center">
        <!-- Greeting Box -->
        <v-col cols="12" md="6">
          <div class="greeting-box">
            Hello {{ currentUser.name }}, welcome to Yaga!
          </div>
        </v-col>
  
        <!-- User Profile Type Box -->
        <v-col cols="12" md="6" class="text-right">
          <div class="profile-type-box">
            {{ currentUser.role === 'SuperAdmin' ? 'SuperAdmin Profile' : currentUser.role === 'Teacher' ? 'Teacher Profile' : 'Student Profile' }}
          </div>
        </v-col>
      </v-row>
  
      <!-- SuperAdmin Section -->
      <div v-if="currentUser.role === 'SuperAdmin'">
        <v-row>
          <v-col>
            <div class="chart-container">
              <canvas id="teachersStudentsChart"></canvas>
            </div>
          </v-col>
          <v-col>
            <div class="chart-container-2">
              <canvas id="activeInactiveUsersChart"></canvas>
            </div>
          </v-col>
        </v-row>
  
        <!-- Diagram Section for SuperAdmin -->
        <div class="diagram-section-header">
          <h3 class="diagram-section-title">My Ladder Logic Diagrams</h3>
  
          <!-- Buttons for Rename and Delete -->
          <div class="diagram-actions">
            <v-btn class="edit-user-button" v-if="selectedDiagrams.length === 1" @click="renameSelectedDiagram">
              <font-awesome-icon :icon="['fas', 'pencil-alt']" />
              <span class="button-text">RENAME DIAGRAM</span>
            </v-btn>
            <v-btn class="delete-user-button" v-if="selectedDiagrams.length > 0" @click="deleteSelectedDiagrams">
              <font-awesome-icon :icon="['fas', 'trash']" />
              <span class="button-text">DELETE DIAGRAM(S)</span>
            </v-btn>
          </div>
        </div>
  
        <table class="diagrams-table">
          <thead>
            <tr>
              <th>Select</th>
              <th>Name</th>
              <th>Created At</th>
              <th>Shared</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="diagram in adminDiagrams" :key="diagram.id">
              <td><input type="checkbox" v-model="selectedDiagrams" :value="diagram.id" /></td>
              <td>{{ diagram.name }}</td>
              <td>{{ diagram.created_at }}</td>
              <td>{{ diagram.shared ? 'Yes' : 'No' }}</td>
            </tr>
          </tbody>
        </table>
      </div>
  
<!-- Teacher Section -->
<div v-if="currentUser.role === 'Teacher'">
  <v-row>
    <v-col>
      <div class="chart-container-t1">
        <canvas id="studentSlotsChart"></canvas>
      </div>
    </v-col>
    <v-col>
      <div class="chart-container-t3">
        <canvas id="teacherDiagramsLeftChart"></canvas>
      </div>
    </v-col>
  </v-row>

  <!-- Teacher's Diagrams -->
  <v-row>
    <!-- My Ladder Logic Diagrams (Created by Teacher) -->
    <v-col cols="12" md="6"> 
      <div>
        <h3 class="diagram-section-title">My Ladder Logic Diagrams</h3>

        <!-- Buttons for Rename and Delete -->
        <div class="diagram-actions">
          <v-btn class="edit-user-button" v-if="selectedDiagrams.length === 1" @click="renameSelectedDiagram">
            <font-awesome-icon :icon="['fas', 'pencil-alt']" />
            <span class="button-text">RENAME DIAGRAM</span>
          </v-btn>
          <v-btn class="delete-user-button" v-if="selectedDiagrams.length > 0" @click="deleteSelectedDiagrams">
            <font-awesome-icon :icon="['fas', 'trash']" />
            <span class="button-text">DELETE DIAGRAM(S)</span>
          </v-btn>
        </div>

        <table class="diagrams-table">
          <thead>
            <tr>
              <th>Select</th>
              <th>Name</th>
              <th>Created At</th>
              <th>Shared</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="diagram in teacherDiagrams" :key="diagram.id">
              <td><input type="checkbox" v-model="selectedDiagrams" :value="diagram.id" /></td>
              <td>{{ diagram.name }}</td>
              <td>{{ diagram.created_at }}</td>
              <td>{{ diagram.shared ? 'Yes' : 'No' }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </v-col>

    <!-- Diagrams Shared by Students -->
    <v-col cols="12" md="6">
      <div>
        <h3 class="diagram-section-title">Ladder Logic Diagrams Shared by Students</h3>

        <!-- Buttons for Delete -->
        <div class="diagram-actions">
          <v-btn class="delete-user-button" v-if="selectedSharedDiagrams.length > 0" @click="deleteSelectedSharedDiagrams">
            <font-awesome-icon :icon="['fas', 'trash']" />
            <span class="button-text">DELETE DIAGRAM(S)</span>
          </v-btn>
        </div>

        <table class="diagrams-table">
          <thead>
            <tr>
              <th>Select</th>
              <th>Name</th>
              <th>Shared By</th>
              <th>Created At</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="diagram in sharedDiagrams" :key="diagram.id">
              <td><input type="checkbox" v-model="selectedSharedDiagrams" :value="diagram.id" /></td>
              <td>{{ diagram.name }}</td>
              <td>{{ diagram.shared_by }}</td>
              <td>{{ diagram.created_at }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </v-col>
  </v-row>
</div>

  
<!-- Student Section -->
<div v-if="currentUser.role === 'Student'">
  <v-row>
    <v-col>
      <div class="chart-container-s1">
        <canvas id="studentDiagramsChart"></canvas>
      </div>
    </v-col>
    <v-col cols="12" md="6" class="text-right">
      <!-- Teacher Information -->
      <div class="profile-type-box-2">
        Your Main Teacher: {{ attachedTeacherName }}
      </div>
    </v-col>
  </v-row>

  <v-row>
    <!-- My Created Diagrams -->
    <v-col cols="12" md="6">
      <div>
        <h3 class="diagram-section-title">My Ladder Logic Diagrams</h3>

        <!-- Buttons for Rename and Delete -->
        <div class="diagram-actions">
          <v-btn class="edit-user-button" v-if="selectedDiagrams.length === 1" @click="renameSelectedDiagram">
            <font-awesome-icon :icon="['fas', 'pencil-alt']" />
            <span class="button-text">RENAME DIAGRAM</span>
          </v-btn>
          <v-btn class="delete-user-button" v-if="selectedDiagrams.length > 0" @click="deleteSelectedDiagrams">
            <font-awesome-icon :icon="['fas', 'trash']" />
            <span class="button-text">DELETE DIAGRAM(S)</span>
          </v-btn>
        </div>

        <table class="diagrams-table">
          <thead>
            <tr>
              <th>Select</th>
              <th>Name</th>
              <th>Created At</th>
              <th>Shared</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="diagram in studentDiagrams" :key="diagram.id">
              <td><input type="checkbox" v-model="selectedDiagrams" :value="diagram.id" /></td>
              <td>{{ diagram.name }}</td>
              <td>{{ diagram.created_at }}</td>
              <td>{{ diagram.shared ? 'Yes' : 'No' }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </v-col>

    <!-- Diagrams Shared by Teacher -->
    <v-col cols="12" md="6">
      <div>
        <h3 class="diagram-section-title">Ladder Logic Diagrams Shared by Teacher</h3>

        <!-- Buttons for Delete -->
        <div class="diagram-actions">
          <v-btn class="delete-user-button" v-if="selectedSharedDiagrams.length > 0" @click="deleteSelectedSharedDiagrams">
            <font-awesome-icon :icon="['fas', 'trash']" />
            <span class="button-text">DELETE DIAGRAM(S)</span>
          </v-btn>
        </div>

        <table class="diagrams-table">
          <thead>
            <tr>
              <th>Select</th>
              <th>Name</th>
              <th>Shared By</th>
              <th>Created At</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="diagram in sharedDiagrams" :key="diagram.id">
              <td><input type="checkbox" v-model="selectedSharedDiagrams" :value="diagram.id" /></td>
              <td>{{ diagram.name }}</td>
              <td>{{ diagram.shared_by }}</td>
              <td>{{ diagram.created_at }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </v-col>
  </v-row>
</div>

    </v-container>
  </template>
  
  
  
  
  <script>
import { ref, onMounted } from "vue";
import axios from "axios";
import Chart from "chart.js/auto";

export default {
  setup() {
    const currentUser = ref({ role: "" });
    const attachedTeacherName = ref("");

    const adminDiagrams = ref([]);
    const teacherDiagrams = ref([]);
    const sharedDiagrams = ref([]);
    const studentDiagrams = ref([]);
    const selectedDiagrams = ref([]); // For selecting diagrams
    const selectedSharedDiagrams = ref([]);  // New ref to track shared diagram selections

    const adminDiagramHeaders = [
      { text: "Select", value: "select" },
      { text: "ID", value: "id" },
      { text: "Name", value: "name" },
      { text: "Shared", value: "shared" },
      { text: "Created At", value: "created_at" },
      { text: "Actions", value: "actions", sortable: false },
    ];

    const teacherDiagramHeaders = adminDiagramHeaders;
    const studentDiagramHeaders = adminDiagramHeaders;
    const sharedDiagramHeaders = [
      { text: "ID", value: "id" },
      { text: "Name", value: "name" },
      { text: "Shared By", value: "shared_by" },
      { text: "Created At", value: "created_at" },
      { text: "Actions", value: "actions", sortable: false },
    ];

    // Fetch user profile data
    const fetchUserData = async () => {
      const token = localStorage.getItem("jwt");
      try {
        const response = await axios.get("/yaga-auth/profile", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        currentUser.value = response.data;

        if (currentUser.value.role === "SuperAdmin") {
          await fetchAdminData();
        } else if (currentUser.value.role === "Teacher") {
          await fetchTeacherData();
        } else if (currentUser.value.role === "Student") {
          await fetchStudentData();
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    // Fetch SuperAdmin's data and render charts
    const fetchAdminData = async () => {
      const token = localStorage.getItem("jwt");
      const response = await axios.get("/yaga-auth/ladder-logic", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      adminDiagrams.value = response.data;
      renderSuperAdminCharts();
    };

    // Fetch Teacher's data and render charts
    const fetchTeacherData = async () => {
      const token = localStorage.getItem("jwt");
      const response = await axios.get("/yaga-auth/ladder-logic/teacher", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      teacherDiagrams.value = response.data.createdDiagrams;
      sharedDiagrams.value = response.data.sharedDiagrams;
      renderTeacherCharts(response.data.studentsCount, response.data.totalDiagrams, response.data.remainingDiagrams);
    };

    // Fetch Student's data and render charts
    const fetchStudentData = async () => {
      const token = localStorage.getItem("jwt");
      const response = await axios.get("/yaga-auth/ladder-logic/student", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      studentDiagrams.value = response.data.createdDiagrams;
      sharedDiagrams.value = response.data.sharedDiagrams;
      attachedTeacherName.value = response.data.attachedTeacherName;
      renderStudentCharts(response.data.totalDiagrams, response.data.remainingDiagrams);
    };

    // Render charts for SuperAdmin
    const renderSuperAdminCharts = async () => {
      try {
        const token = localStorage.getItem("jwt");

        // Fetch the data for teachers and students
        const usersResponse = await axios.get('/yaga-auth/users-summary', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const { teachersCount, studentsCount } = usersResponse.data;

        // Fetch the data for active and inactive users per month
        const activityResponse = await axios.get('/yaga-auth/user-activity-summary', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const { months, activeUsers, inactiveUsers } = activityResponse.data;

        // Render the Teachers vs Students pie chart
        new Chart(document.getElementById("teachersStudentsChart"), {
          type: "pie",
          data: {
            labels: ["Teachers", "Students"],
            datasets: [
              {
                data: [teachersCount, studentsCount], // Use real data from API
                backgroundColor: ["#FF6384", "#36A2EB"],
              },
            ],
          },
        });

        // Render the Active vs Inactive Users per Month bar chart
        new Chart(document.getElementById("activeInactiveUsersChart"), {
          type: "bar",
          data: {
            labels: months, // Use real data from API
            datasets: [
              {
                label: "Active Users",
                data: activeUsers, // Use real data from API
                backgroundColor: "#4BC0C0",
              },
              {
                label: "Inactive Users",
                data: inactiveUsers, // Use real data from API
                backgroundColor: "#FFCE56",
              },
            ],
          },
        });
      } catch (error) {
        console.error("Error fetching data for charts:", error);
        alert("Failed to fetch data for charts.");
      }
    };

    // Render charts for Teachers
    const renderTeacherCharts = (studentsCount, totalDiagrams, remainingDiagrams) => {
      // Teacher chart: Students capacity
      new Chart(document.getElementById("studentSlotsChart"), {
        type: "doughnut",
        data: {
          labels: ["Added Students", "Remaining Slots"],
          datasets: [
            {
              data: [studentsCount, 10 - studentsCount], // Assuming a total of 10 students capacity
              backgroundColor: ["#FF6384", "#36A2EB"],
            },
          ],
        },
      });


      // Teacher chart: Diagrams created vs remaining
      new Chart(document.getElementById("teacherDiagramsLeftChart"), {
        type: "doughnut",
        data: {
          labels: ["Created Diagrams", "Remaining Diagrams to Create"],
          datasets: [
            {
              data: [totalDiagrams, remainingDiagrams], // Assume total capacity of 60 diagrams
              backgroundColor: ["#FF6384", "#36A2EB"],
            },
          ],
        },
      });
    };

    // Render charts for Students
    const renderStudentCharts = (totalDiagrams, remainingDiagrams) => {
      // Student chart: Diagrams created vs remaining
      new Chart(document.getElementById("studentDiagramsChart"), {
        type: "pie",
        data: {
          labels: ["Created Diagrams", "Remaining Diagrams to Create"],
          datasets: [
            {
              data: [totalDiagrams, remainingDiagrams], // Assuming total capacity of 20 diagrams
              backgroundColor: ["#FF6384", "#36A2EB"],
            },
          ],
        },
      });
    };

    const renameDiagram = async (diagram) => {
      if (typeof diagram === 'object' && diagram.id && diagram.name) {
        const newName = prompt("Enter new diagram name:", diagram.name);
        if (newName) {
          const token = localStorage.getItem("jwt");
          try {
            await axios.put(`/yaga-auth/ladder-logic/${diagram.id}/rename`, { name: newName }, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            });
            diagram.name = newName;
          } catch (error) {
            console.error("Error renaming diagram:", error);
          }
        }
      } else {
        console.error("Invalid diagram object passed to renameDiagram:", diagram);
      }
    };

    const renameSelectedDiagram = () => { 
  if (selectedDiagrams.value.length === 1) {
    const selectedId = selectedDiagrams.value[0];

    // Check if the diagram exists in the adminDiagrams, teacherDiagrams, studentDiagrams, or sharedDiagrams
    let diagramToRename;
    let isSharedDiagram = false;

    if (currentUser.value.role === "SuperAdmin") {
      // Check admin diagrams
      diagramToRename = adminDiagrams.value.find(diagram => diagram.id === selectedId);
    } else if (currentUser.value.role === "Teacher") {
      // Check teacher-created diagrams
      diagramToRename = teacherDiagrams.value.find(diagram => diagram.id === selectedId);
      
      // If not found in teacher-created, check shared diagrams
      if (!diagramToRename) {
        diagramToRename = sharedDiagrams.value.find(diagram => diagram.id === selectedId);
        if (diagramToRename) {
          isSharedDiagram = true;
        }
      }
    } else if (currentUser.value.role === "Student") {
      // Check student-created diagrams
      diagramToRename = studentDiagrams.value.find(diagram => diagram.id === selectedId);

      // If not found in student-created, check shared diagrams
      if (!diagramToRename) {
        diagramToRename = sharedDiagrams.value.find(diagram => diagram.id === selectedId);
        if (diagramToRename) {
          isSharedDiagram = true;
        }
      }
    }

    // If diagram is found, rename it
    if (diagramToRename) {
      if (!isSharedDiagram) {
        renameDiagram(diagramToRename); // For created diagrams

      } 
    } else {
      console.error("No diagram found with ID:", selectedId);
    }
  }
};



    const deleteSelectedDiagrams = async () => {
  const token = localStorage.getItem("jwt");
  if (confirm("Are you sure you want to delete the selected diagrams?")) {
    try {
      await Promise.all(
        selectedDiagrams.value.map((id) =>
          axios.delete(`/yaga-auth/ladder-logic/${id}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
        )
      );

      // Remove the selected diagrams from the appropriate list based on role
      if (currentUser.value.role === "SuperAdmin") {
        adminDiagrams.value = adminDiagrams.value.filter(
          (diagram) => !selectedDiagrams.value.includes(diagram.id)
        );
      } else if (currentUser.value.role === "Teacher") {
        teacherDiagrams.value = teacherDiagrams.value.filter(
          (diagram) => !selectedDiagrams.value.includes(diagram.id)
        );
        sharedDiagrams.value = sharedDiagrams.value.filter(
          (diagram) => !selectedDiagrams.value.includes(diagram.id)
        );
      } else if (currentUser.value.role === "Student") {
        studentDiagrams.value = studentDiagrams.value.filter(
          (diagram) => !selectedDiagrams.value.includes(diagram.id)
        );
        sharedDiagrams.value = sharedDiagrams.value.filter(
          (diagram) => !selectedDiagrams.value.includes(diagram.id)
        );
      }

      selectedDiagrams.value = [];
    } catch (error) {
      console.error("Error deleting diagrams:", error);
    }
  }
};

const deleteSelectedSharedDiagrams = async () => {
  const token = localStorage.getItem("jwt");
  if (confirm("Are you sure you want to delete the selected shared diagrams?")) {
    try {
      await Promise.all(
        selectedSharedDiagrams.value.map((id) =>
          axios.delete(`/yaga-auth/ladder-logic/${id}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
        )
      );

      sharedDiagrams.value = sharedDiagrams.value.filter(
        (diagram) => !selectedSharedDiagrams.value.includes(diagram.id)
      );

      selectedSharedDiagrams.value = [];
    } catch (error) {
      console.error("Error deleting shared diagrams:", error);
    }
  }
};

const unlinkDiagram = async (id) => {
  const token = localStorage.getItem("jwt");
  if (confirm("Are you sure you want to unlink this diagram?")) {
    try {
      await axios.delete(`/yaga-auth/ladder-logic/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // Update the sharedDiagrams based on role
      if (currentUser.value.role === "Teacher") {
        sharedDiagrams.value = sharedDiagrams.value.filter((diagram) => diagram.id !== id);
      } else if (currentUser.value.role === "Student") {
        sharedDiagrams.value = sharedDiagrams.value.filter((diagram) => diagram.id !== id);
      }

      selectedDiagrams.value = [];
    } catch (error) {
      console.error("Error unlinking diagram:", error);
    }
  }
};


    onMounted(() => {
      fetchUserData();
    });

    return {
      currentUser,
      attachedTeacherName,
      adminDiagrams,
      teacherDiagrams,
      sharedDiagrams,
      studentDiagrams,
      selectedDiagrams,
      renameDiagram,
      deleteSelectedDiagrams,
      unlinkDiagram,
      renameSelectedDiagram,
      teacherDiagramHeaders,
      studentDiagramHeaders,
      sharedDiagramHeaders,
      selectedSharedDiagrams,
      deleteSelectedSharedDiagrams,

    };
  },
};
</script>
  

  
  <style scoped>
.greeting-box {
  padding: 10px;
  border-radius: 10px;
  background: linear-gradient(to bottom, #ffc300, #ffd60a); /* Yellow background */
  color: #000814; /* Text color */
  font-size: 1.1rem;
  font-weight: bold;
}

.profile-type-box {
  padding: 10px;
  border-radius: 10px;
  background: linear-gradient(to bottom, #9ad7ff, #03abff); /* Blue background */
  color: #000814; /* Text color */
  font-size: 1.1rem;
  font-weight: bold;
  text-align: right;
}
.profile-type-box-2 {
  padding: 10px;
  border-radius: 10px;
  background: linear-gradient(to bottom, #a1ff9a, #03ff03); /* Blue background */
  color: #000814; /* Text color */
  font-size: 1.1rem;
  font-weight: bold;
  text-align: right;
}

/* Reduced chart container size */
.chart-container {
  width: 50%;
  height: auto;
  margin: 0 auto;
  padding-top: 15 px;
  padding-bottom: 15 px;
}

.chart-container-2 {
  height: auto;
  margin: 0 auto;
  padding-top: 15 px;
  padding-bottom: 15 px;
}

.chart-container-t1 {
  width: 50%;
  height: auto;
  margin: 0 auto;
  padding-top: 15 px;
  padding-bottom: 15 px;
}

.chart-container-t3 {
  width: 50%;
  height: auto;
  margin: 0 auto;
  padding-top: 15 px;
  padding-bottom: 15 px;
}
.chart-container-s1 {
  width: 50%;
  height: auto;
  margin: 0 auto;
  padding-top: 15 px;
  padding-bottom: 15 px;
}


.diagram-section-title {
  color: #03abff; /* Title color */
  margin-bottom: 10px;
  font-size: 24px;
}

.diagrams-table {
  width: 100%;
  border-collapse: collapse;
}

.diagrams-table th,
.diagrams-table td {
  text-align: center; /* Align headers and data to the center */
  padding: 8px;
}

.diagrams-table th {
  color: #ffc300; /* Header text color */
  border-bottom: 2px solid #03abff; /* Bold line below the headers */
}

.diagrams-table td {
  color: #9ad7ff; /* Entry text color */
  border-bottom: 1px solid #ddd; /* Thinner line between entries */
}

.v-checkbox {
  margin: 0 auto;
}

/* Hide buttons initially */
v-btn {
    margin-top: 10px;
}

/* Display buttons when elements are selected */
[v-if="selectedDiagrams.length > 0"] {
  display: block;
}

/* Delete button style */
.v-btn[color="red"] {
  background: linear-gradient(to bottom, #ff0000, #920000);
  color: white;
}

.v-btn[color="red"]:hover {
  filter: brightness(0.9);
}
.diagram-section-header {
  display: flex;
  justify-content: space-between;
  align-items: top;
  margin-top: 10px;

}

.diagram-actions {
  display: flex;
  gap: 10px;
}

/* Rename Button */
.edit-user-button {
  background: linear-gradient(to bottom, #ffc300, #ffd60a);
  color: black;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
}

.edit-user-button:hover {
  filter: brightness(0.9);
}

/* Delete Button */
.delete-user-button {
  background: linear-gradient(to bottom, #ff0000, #920000);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
}

.delete-user-button:hover {
  filter: brightness(0.9);
}

.button-text {
  margin-left: 10px;
}
  </style>