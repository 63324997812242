import { createStore } from 'vuex';

export default createStore({
  state: {
    token: localStorage.getItem('jwt') || null,
    ladderLogic: {
      diagramId: null,
    },
    digitalTwin: {
      vrScenarioId: null,
    },
    iiotDataSources: {
      selectedDiagramId: null,
      selectedVRScenarioId: null,
      variablesTable: [],
      vrTagsTable: [],
    },
    selectedMenu: 'homepage',  // Add selectedMenu to the state
    toggledContact: null, // New property to store the toggled contact

  },
  mutations: {
    setToken(state, token) {
      state.token = token;
      localStorage.setItem('jwt', token);
    },
    clearToken(state) {
      state.token = null;
      localStorage.removeItem('jwt');
    },
    setLadderLogicToRun(state, payload) {
      state.ladderLogic.diagramId = payload.diagramId;
    },
    setDigitalTwinToRun(state, payload) {
      state.digitalTwin.vrScenarioId = payload.vrScenarioId;
    },
    setIIoTDataSources(state, payload) {
      // Update each property individually to maintain reactivity
      state.iiotDataSources.selectedDiagramId = payload.selectedDiagramId;
      state.iiotDataSources.selectedVRScenarioId = payload.selectedVRScenarioId;
  
      // To maintain reactivity, replace the entire array
      state.iiotDataSources.variablesTable = [...payload.variablesTable];
      state.iiotDataSources.vrTagsTable = [...payload.vrTagsTable];
    },
    setSelectedMenu(state, menu) {  // Mutation to set selectedMenu
      state.selectedMenu = menu;
    }, 
    updateVrTagValue(state, payload) {
      const { tagName, value } = payload;  
      const index = state.iiotDataSources.vrTagsTable.findIndex(t => t.name === tagName);
      if (index !== -1) {
        const updatedTag = {
          ...state.iiotDataSources.vrTagsTable[index],
          value: value,
        };
        state.iiotDataSources.vrTagsTable.splice(index, 1, updatedTag);
      }
      
    },
    
    
  
    toggleContact(state, payload) {
      const { elementId, newState } = payload;
      const index = state.iiotDataSources.variablesTable.findIndex(variable => variable.id === elementId);
      if (index !== -1) {
        console.log(`IIOT- toggleContact mutation: updating variable with id ${elementId} to value ${newState}`);
        // Update the object directly to trigger reactivity
        state.iiotDataSources.variablesTable[index].value = newState;
        state.toggledContact = { elementId, newState };
      } else {
        console.log("IIOT- toggleContact mutation: variable with id", elementId, "not found");
      }
    },
    
    
    resetToggledContact(state) {
      state.toggledContact = null;
    },
    
    setConveyorState(state, payload) {
      // Assume payload contains a 'running' boolean property
      const vrTag = state.iiotDataSources.vrTagsTable.find(tag => tag.name === "Motor 1");
      if (vrTag) {
        vrTag.value = payload.running; // Update the VR tag value based on the conveyor state
      }
    },
    
  },
  actions: {
    login({ commit }, token) {
      commit('setToken', token);
    },
    logout({ commit }) {
      commit('clearToken');
    },
    setLadderLogicToRun({ commit }, payload) {
      commit('setLadderLogicToRun', payload);
    },
    setDigitalTwinToRun({ commit }, payload) {
      commit('setDigitalTwinToRun', payload);
    },
    setIIoTDataSources({ commit }, payload) {
      commit('setIIoTDataSources', payload);
    },
    setSelectedMenu({ commit }, menu) {  // Action to set selectedMenu
      commit('setSelectedMenu', menu);
    },
    toggleLadderLogicContact({ commit }, payload) {
      commit('toggleContact', payload);
    },
    setConveyorState({ commit }, payload) {
      console.log("Action setConveyorState called with:", payload);
      commit('setConveyorState', payload);
    },
    updateVrTagValue({ commit }, payload) {
      commit('updateVrTagValue', payload);
    },
  },
  getters: {
    isLoggedIn: state => !!state.token,
    getLadderLogic: state => state.ladderLogic,
    getDigitalTwin: state => state.digitalTwin,
    getIIoTDataSources: (state) => state.iiotDataSources,
    getSelectedMenu: state => state.selectedMenu,  // Getter to get selectedMenu
    getLadderLogicState: (state) => state, // Return the current state, including toggled contacts

  }
});
