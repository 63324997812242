<template>
  <div class="vr-container">
    <!-- Babylon.js canvas -->
    <canvas ref="renderCanvas" class="canvas-container"></canvas>
  </div>
</template>

<script>
import {
  Engine,
  Scene,
  ArcRotateCamera,
  DirectionalLight,
  HemisphericLight,
  Vector3,
  SceneLoader,
  ShadowGenerator,
  Color3,
  Texture,
  HavokPlugin,
  MeshBuilder,
  Animation,
  StandardMaterial,
  ActionManager,
  ExecuteCodeAction,
  Sound, // Import Sound from Babylon.js
} from "@babylonjs/core";
import HavokPhysics from "@babylonjs/havok";
import "@babylonjs/core/XR";
import "@babylonjs/loaders/glTF";
import { PhysicsAggregate } from "@babylonjs/core/Physics";
import { PhysicsShapeType } from "@babylonjs/core/Physics";
import { useStore } from "vuex"; // Import Vuex store
import { ref, watch, onMounted, onUnmounted } from 'vue'; // Import onUnmounted

export default {
  name: "CB-Level1",
  setup() {
    const store = useStore();
    const renderCanvas = ref(null);

    // Reactive variables
    const conveyorSpeed = 1.5;
    const conveyorRunning = ref(false);
    let boxAnimation = null;
    let conveyorAnimationObserver = null;
    let boxPhysics = null;
    let boxStartPosition = null;
    let boxStartRotation = null;
    let scene = null;
    let meshes = null;
    let box = null;
    let boxAnimationData = null;

    // Sound variables
    let buttonClickSound = null;
    let factorySound = null;

    // Babylon.js scene initialization
    const initBabylonScene = async () => {
      const canvas = renderCanvas.value;
      const engine = new Engine(canvas, true, { preserveDrawingBuffer: true });
      scene = new Scene(engine);
      scene.clearColor = new Color3(0.95, 0.95, 0.95);

      const camera = new ArcRotateCamera(
        "camera",
        0,
        Math.PI / 2.5,
        5,
        new Vector3(5, 1, -3.85),
        scene
      );
      camera.attachControl(canvas, true);

      const light = new DirectionalLight("dirLight", new Vector3(0, -1, 1), scene);
      light.position = new Vector3(0, 5, -10);
      light.intensity = 0.6;

      const hemisphericLight = new HemisphericLight("hemiLight", new Vector3(0, 1, 0), scene);
      hemisphericLight.intensity = 0.3;

      const environment = scene.createDefaultEnvironment({
        enableGroundShadow: true,
        groundYBias: 2.8,
      });
      environment.setMainColor(new Color3(0.95, 0.95, 0.95));
      if (environment.skybox) {
        environment.skybox.material.alpha = 0; // Make the ground transparent
      }
      if (environment.ground) {
        environment.ground.isVisible = false; // Hide the ground
      }


      const shadowGenerator = new ShadowGenerator(1024, light);
      shadowGenerator.useBlurExponentialShadowMap = true;
      shadowGenerator.blurKernel = 32;

      const havokInstance = await HavokPhysics();
      const havokPlugin = new HavokPlugin(true, havokInstance);
      scene.enablePhysics(new Vector3(0, -9.8, 0), havokPlugin);

      // Load sounds
      buttonClickSound = new Sound(
        "buttonClick",
        "/assets/button_click.mp3",
        scene,
        null,
        { autoplay: false }
      );

      factorySound = new Sound(
        "factorySound",
        "/assets/factory_sound.mp3",
        scene,
        null,
        { loop: true, autoplay: false }
      );

      // Load the GLTF file
      await new Promise((resolve, reject) => {
        SceneLoader.ImportMesh(
          "",
          "/assets/",
          "ConveyorB.gltf",
          scene,
          (loadedMeshes) => {
            let conveyorRoot = loadedMeshes[0];
            conveyorRoot.position.y = 2.5;

            const meshMap = {};
            loadedMeshes.forEach((mesh) => {
              meshMap[mesh.name] = mesh;
            });

            loadedMeshes.forEach((mesh) => {
              if (mesh.name.startsWith("RailC_") && mesh.getTotalVertices() > 0) {
                shadowGenerator.addShadowCaster(mesh);
                new PhysicsAggregate(mesh, PhysicsShapeType.BOX, { mass: 0, restitution: 0.1 }, scene);
              }

              if (mesh.name.startsWith("RailC_")) {
                const material = mesh.material;
                if (material) {
                  if (!material.albedoTexture) {
                    material.albedoTexture = new Texture("/assets/Rail_AlbedoTransparency.png", scene);
                    material.bumpTexture = new Texture("/assets/Rail_Normal.png", scene);
                    material.specularTexture = new Texture("/assets/Rail_SpecularSmoothness.png", scene);
                  }
                }
              }

              if (mesh.name.startsWith("PlatA_") || mesh.name.startsWith("PlatB_")) {
                shadowGenerator.addShadowCaster(mesh);
                new PhysicsAggregate(mesh, PhysicsShapeType.BOX, { mass: 0, restitution: 0.1 }, scene);
              }
            });

            const startButton = meshMap["Button_Start_1"];
            if (startButton) {
              setupButtonInteraction(startButton, scene, "start");
            }

            const stopButton = meshMap["Button_Stop_1"];
            if (stopButton) {
              setupButtonInteraction(stopButton, scene, "stop");
            }

            box = MeshBuilder.CreateBox("box", { size: 0.5 }, scene);
            const railStart = scene.getMeshByName("RailC_1");
            if (railStart) {
              const railBoundingInfo = railStart.getBoundingInfo();
              const railCenter = railBoundingInfo.boundingBox.centerWorld;
              const boxBoundingInfo = box.getBoundingInfo();
              const boxHeight = boxBoundingInfo.boundingBox.extendSizeWorld.y;

              box.position = railCenter.clone();
              box.position.y = railBoundingInfo.boundingBox.extendSizeWorld.y + boxHeight + 0.1;

              box.rotation = railStart.absoluteRotationQuaternion.toEulerAngles();
              boxPhysics = new PhysicsAggregate(box, PhysicsShapeType.BOX, { mass: 1, restitution: 0.5 }, scene);
              shadowGenerator.addShadowCaster(box);

              boxStartPosition = box.position.clone();
              boxStartRotation = box.rotation.clone();

              setTimeout(() => {
                boxPhysics.dispose();
                const { positionKeyFrames, rotationKeyFrames, cumulativeFrameCount } = prepareBoxAnimations(
                  scene,
                  box,
                  boxHeight
                );

                const animationBoxPosition = new Animation(
                  "boxPositionAnimation",
                  "position",
                  30,
                  Animation.ANIMATIONTYPE_VECTOR3,
                  Animation.ANIMATIONLOOPMODE_CONSTANT
                );

                const animationBoxRotation = new Animation(
                  "boxRotationAnimation",
                  "rotation",
                  30,
                  Animation.ANIMATIONTYPE_VECTOR3,
                  Animation.ANIMATIONLOOPMODE_CONSTANT
                );

                animationBoxPosition.setKeys(positionKeyFrames);
                animationBoxRotation.setKeys(rotationKeyFrames);

                box.animations.push(animationBoxPosition);
                box.animations.push(animationBoxRotation);

                boxAnimationData = {
                  cumulativeFrameCount,
                  box,
                };
              }, 2000);
            }

            meshes = loadedMeshes;

            resolve();
          },
          null,
          (scene, message) => {
            //console.error("Error loading GLTF:", message);
            reject(message);
          }
        );
      });

      let isXRSupported = await checkXRSupport();
      if (isXRSupported) {
        const xrHelper = await scene.createDefaultXRExperienceAsync({
          floorMeshes: [environment.ground],
        });
        xrHelper.baseExperience.onStateChangedObservable.add((state) => {
          console.log("XR State Changed:", state);
        });
        xrHelper.teleportation.addFloorMesh(environment.ground);
      }

      engine.runRenderLoop(() => {
        scene.render();
      });

      window.addEventListener("resize", () => {
        engine.resize();
      });
    };

    // Setup button interactions with correct animation handling
    const setupButtonInteraction = (buttonMesh, scene, type) => {
      const colorMesh = buttonMesh.getChildMeshes().find((m) => m.name === `${buttonMesh.name}_Color`) || buttonMesh;
      const originalMaterial = colorMesh.material;
      const pressedMaterial = new StandardMaterial(`pressedMaterial_${type}`, scene);

      pressedMaterial.diffuseColor = type === "start" ? new Color3(0.5, 1, 0.5) : new Color3(1, 0.5, 0.5);

      buttonMesh.actionManager = new ActionManager(scene);
      buttonMesh.actionManager.registerAction(
        new ExecuteCodeAction(ActionManager.OnPickTrigger, () => {
          // Play button click sound
          if (buttonClickSound) {
            buttonClickSound.play();
          }

          // Animate button press and release

          // Change the material to indicate the button is pressed
          colorMesh.material = pressedMaterial;

          // Define the down position for the button animation
          const downPosition = buttonMesh.position.clone();
          downPosition.y -= 0.05;

          // Create animations for pressing down and releasing the button
          const pressDownAnimation = new Animation("pressDownAnimation", "position", 30, Animation.ANIMATIONTYPE_VECTOR3);
          pressDownAnimation.setKeys([
            { frame: 0, value: buttonMesh.position.clone() },
            { frame: 5, value: downPosition },
          ]);

          const releaseAnimation = new Animation("releaseAnimation", "position", 30, Animation.ANIMATIONTYPE_VECTOR3);
          releaseAnimation.setKeys([
            { frame: 0, value: downPosition },
            { frame: 5, value: buttonMesh.position.clone() },
          ]);

          // Perform press and release animations sequentially
          scene.beginDirectAnimation(buttonMesh, [pressDownAnimation], 0, 5, false, 1.0, () => {
            scene.beginDirectAnimation(buttonMesh, [releaseAnimation], 0, 5, false, 1.0, () => {
              // Reset the material to the original after release
              colorMesh.material = originalMaterial;
            });
          });

          // Dispatch event or update store based on button type
          if (type === "start") {
            handleButtonPress("Start Button");
          } else if (type === "stop") {
            handleButtonPress("Stop Button");
          }
        })
      );
    };

    const handleButtonPress = (buttonName) => {
      //console.log(`IIOT- Button pressed: ${buttonName}`);

      // Get the linked VR tag
      const iiotDataSources = store.getters.getIIoTDataSources;
      const linkedVrTag = iiotDataSources.vrTagsTable.find((tag) => tag.name === buttonName);

      if (linkedVrTag && linkedVrTag.direction === 'input') {
       // console.log("IIOT- VR Tag Found:", linkedVrTag);

        // Dispatch action to update the VR tag value
        store.dispatch("updateVrTagValue", { tagName: buttonName, value: true });

        setTimeout(() => {
          store.dispatch("updateVrTagValue", { tagName: buttonName, value: false });
        }, 100);
      } 
    };

    // Start the conveyor animation
    const startConveyor = () => {
      if (conveyorRunning.value) return;
      conveyorRunning.value = true;
      //console.log("IIOT- conveyorRunning.value", conveyorRunning.value);

      if (!conveyorAnimationObserver) {
        conveyorAnimationObserver = scene.onBeforeRenderObservable.add(() => {
          meshes.forEach((mesh) => {
            if (mesh.name.startsWith("RailC_")) {
              const material = mesh.material;
              if (material && material.albedoTexture) {
                material.albedoTexture.uOffset += conveyorSpeed * 0.0105;
                // console.log("IIOT- starting conveyor animation");
              }
            }
          });
        });
      }

      if (boxPhysics) {
        boxPhysics.dispose();
        boxPhysics = null;
      }

      if (boxStartPosition && boxStartRotation) {
        box.position.copyFrom(boxStartPosition);
        box.rotation.copyFrom(boxStartRotation);
      }

      if (boxAnimation) {
        boxAnimation.restart();
      } else if (boxAnimationData) {
        const { cumulativeFrameCount, box: animationBox } = boxAnimationData;

        if (animationBox) {
          boxAnimation = scene.beginAnimation(animationBox, 0, cumulativeFrameCount, false);
          boxAnimation.speedRatio = conveyorSpeed * 0.25;
          // console.log("IIOT- starting box animation");

          boxAnimation.onAnimationEnd = () => {
            boxPhysics = new PhysicsAggregate(animationBox, PhysicsShapeType.BOX, { mass: 1, restitution: 0.5 }, scene);
          };
        } 
      }

      // Play factory sound
      if (factorySound) {
        factorySound.play();
      }
    };

    // Stop the conveyor animation
    const stopConveyor = () => {
      if (!conveyorRunning.value) return;
      conveyorRunning.value = false;

      if (conveyorAnimationObserver) {
        scene.onBeforeRenderObservable.remove(conveyorAnimationObserver);
        conveyorAnimationObserver = null;
      }

      if (boxAnimation) {
        boxAnimation.pause();
        if (!boxPhysics) {
          boxPhysics = new PhysicsAggregate(box, PhysicsShapeType.BOX, { mass: 1, restitution: 0.5 }, scene);
        }
      }

      // Stop factory sound
      if (factorySound && factorySound.isPlaying) {
        factorySound.stop();
      }
    };

    // Prepare box animations
    const prepareBoxAnimations = (scene, box, boxHeight) => {
      // Extract rail positions for keyframes
      const positionKeyFrames = [];
      const rotationKeyFrames = [];

      let cumulativeFrameCount = 0;
      let cumulativeRotationY = box.rotation.y;

      for (let i = 1; i <= 17; i++) {
        const rail = scene.getMeshByName(`RailC_${i}`);
        if (rail) {
          const railBoundingInfo = rail.getBoundingInfo();
          if (!railBoundingInfo) {
            //console.error(`IIOT- RailC_${i} does not have bounding info.`);
            continue;
          }
          const railPosition = railBoundingInfo.boundingBox.centerWorld.clone();

          // Ensure that all values are properly set
          if (!railPosition || !railBoundingInfo.boundingBox.extendSizeWorld) {
           // console.error(`IIOT- Invalid rail position or bounding info for RailC_${i}`);
            continue;
          }

          // Handle elbow rails
          let frameInterval = 10;
          if (i === 6 || i === 7 || i === 14 || i === 15) {
            cumulativeRotationY += (i === 6 || i === 7) ? -Math.PI / 2 : Math.PI / 2;
            frameInterval = 12.5;
          }

          cumulativeFrameCount += frameInterval;

          // Update the y position for the box above the rail
          railPosition.y = railBoundingInfo.boundingBox.extendSizeWorld.y + boxHeight + 0.9;

          // Validate and add position keyframes
          if (railPosition) {
            positionKeyFrames.push({ frame: cumulativeFrameCount, value: railPosition });
          } 

          // Add rotation keyframes
          const currentRotation = box.rotation.clone();
          currentRotation.y = cumulativeRotationY;
          if (currentRotation) {
            rotationKeyFrames.push({ frame: cumulativeFrameCount, value: currentRotation });
          } 
        }
      }

      return { positionKeyFrames, rotationKeyFrames, cumulativeFrameCount };
    };

    // Check for WebXR support
    const checkXRSupport = async () => {
      if (navigator.xr) {
        try {
          const isSupported = await navigator.xr.isSessionSupported("immersive-vr");
          return isSupported;
        } catch (error) {
          //console.error("Error checking WebXR support:", error);
          return false;
        }
      }
      return false;
    };

    const unifiedWatcher = () => {
      watch(
        () => store.getters.getIIoTDataSources.vrTagsTable,
        (newVrTags) => {
          // Find the VR tag for "Motor 1" that is marked as an output
          const motorVrTag = newVrTags.find((tag) => tag.name === "Motor 1" && tag.direction === "output");
          
          if (motorVrTag) {
           // console.log(`IIOT- Motor 1 state changed: VR Tag (${motorVrTag.value})`);
            
            // Start or stop the conveyor based on Motor 1 state
            if (motorVrTag.value && !conveyorRunning.value) {
             // console.log('IIOT- Starting conveyor due to Motor 1 state.');
              startConveyor();
            } else if (!motorVrTag.value && conveyorRunning.value) {
             // console.log('IIOT- Stopping conveyor due to Motor 1 state.');
              stopConveyor();
            }
          } else {
            console.log("IIOT- Motor 1 tag or improperly configured.");
          }
        },
        { deep: true }
      );
    };

    onMounted(() => {
      initBabylonScene().then(() => {
        // Once the scene is initialized, proceed to set initial states.
        const motorVrTag = store.getters.getIIoTDataSources.vrTagsTable.find(
          (tag) => tag.name === "Motor 1" && tag.direction === "output"
        );

        if (motorVrTag) {
          conveyorRunning.value = motorVrTag.value; // Set conveyorRunning to match the initial state
          if (conveyorRunning.value) {
            startConveyor(); // Start the conveyor if the motor is already running
          }
        }
        unifiedWatcher();
      });
    });

    // Cleanup in onUnmounted
    onUnmounted(() => {
      if (conveyorAnimationObserver) {
        scene.onBeforeRenderObservable.remove(conveyorAnimationObserver);
      }
      // Dispose sounds
      if (buttonClickSound) {
        buttonClickSound.dispose();
      }
      if (factorySound) {
        factorySound.dispose();
      }
    });

    return {
      renderCanvas, // Expose renderCanvas to the template
    };
  },
};
</script>

<style scoped>
.vr-container {
  width: 100%;
  height: 100%;
  aspect-ratio: 16 / 6.5;
  margin: 0;
  padding: 0;
  display: flex;
}

.canvas-container {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  display: block;
}
</style>
