/* global gtag */
import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '@/views/HomeView.vue';
import AugmentedVirtuality from '@/views/AugmentedVirtuality.vue';
import EUFundedProjects from '@/views/EUFundedProjects.vue';
import MedusaBlog from '@/views/MedusaBlog.vue';
import MedusaContact from '@/views/MedusaContact.vue';
import BlogPostDetail from '@/views/BlogPostDetail.vue';
import YagaDashboard from '@/views/YagaDashboard.vue';
import store from '@/store';  // Ensure this import is correct

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView,
    meta: { title: 'Medusa Digital - Home' }
  },
  {
    path: '/AugmentedVirtualityTraining',
    name: 'AV & VR Automation Trainer',
    component: AugmentedVirtuality,
    meta: { title: 'Medusa Digital - AV & VR Automation Trainer' }
  },
  {
    path: '/EUFundedProjects',
    name: 'EU Funded Projects',
    component: EUFundedProjects,
    meta: { title: 'Medusa Digital - EU Funded Projects' }
  },
  {
    path: '/MedusaBlog',
    name: 'Blog',
    component: MedusaBlog,
    meta: { title: 'Medusa Digital - Blog' }
  },
  {
    path: '/MedusaContact',
    name: 'Contact',
    component: MedusaContact,
    meta: { title: 'Medusa Digital - Contact' }
  },
  {
    path: '/blog/:slug',
    name: 'BlogPostDetail',
    component: BlogPostDetail,
    meta: { title: 'Medusa Digital - Blog Post' }
  },
  {
    path: '/YagaDashboard',
    name: 'YagaDashboard',
    component: YagaDashboard,
    meta: { title: 'Medusa Digital - YagaDashboard', requiresAuth: true, hideHeaderFooter: true }
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || 'Default Title';

  if (to.meta.requiresAuth && !store.getters.isLoggedIn) {
    next('/');
  } else {
    next();
  }

  // Google Analytics tracking
  if (typeof gtag === 'function') {
    gtag('config', 'G-JS6K4106NH', {
      page_path: to.path,
      page_name: to.name,
      page_title: document.title
    });
  }

  console.log('Navigating from', from.path, 'to', to.path);
});

export default router;